/* Estilo para el input */
input[type="tel"] {
    /* Hace que el fondo y el borde del input sean transparentes */
    background-color: transparent;
    border: none;
    border-bottom: 1px solid transparent; /* Puedes personalizar el borde según sea necesario */
  }

  /* Estilo para el input de tipo "tel" cuando está enfocado */
input[type="tel"]:focus {
    /* Elimina completamente el borde cuando el input está enfocado */
    outline: none;
    border: none;
  }