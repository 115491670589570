/* .fc-day.fc-timegrid-col {
    background-color: rgba(110, 10, 10, 0.2);
} */

/* Se agregan variables para el manejo de los tipos de interacciones */

.color-custom-WithoutInteract {
    color: #E20000;
}

.color-custom-LowInt {
    color: #FF6C0E;
}

.color-custom-MediumInt {
    color: #E2B900;
}

.color-custom-HighInt {
    color: #62B702;
}
.color-custom-VeryHighInt {
    color: #02DE87;
}

.DateInput_input {
    background-color: #ffff;
    border: 1px solid #e4e7e7;
    border-radius: 5px;
    padding: 4px;
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    font-weight: 500;
}
  

.CalendarDay__selected_span {
  background: #19D3C5; 
  color: white; 
  border: 1px solid transparent; 
}

.CalendarDay__selected {
  background: #003462;
  color: white;
}

.CalendarDay__selected:hover {
  background: #FF6C0E;
  color: white;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #19D3C5;
}